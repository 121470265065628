<template>
  <page-setup>
    <v-container fluid class="ma-0 pa-0 fill-height px-10 mt-5">
      <v-row dense>
        <v-col>
          <div class="d-flex align-center my-4">
            <div class="text-h5 text-color-main font-weight-black mr-4">
              {{ title }}
            </div>
            <div class="field-width">
              <v-text-field
                v-model="params.search"
                outlined
                dense
                hide-details
                placeholder="Quick Search"
                class="rounded-lg field-width"
                append-icon="mdi-magnify"
                @input="getPrayers"
              ></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <div></div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="prayers"
            item-key="id"
            class="rounded-lg mb-5"
            :loading="loading"
            :server-items-length="totalItems"
            :options.sync="options"
            @update:options="paginate"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <div class="text-no-wrap">
                {{ item.created_at | MIXINS_FULL_DATE }}
              </div>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <div class="text-no-wrap">
                {{ item.name }}
              </div>
            </template>

            <!-- <template v-slot:[`item.request`]="{ item }">
              <div style="width:auto">
                {{ item.request }}
              </div>
            </template> -->

            <template v-slot:[`item.action`]="{ item }">
                <delete-item-dialog
                  :itemID="item.id"
                  :setup="setupDeleteDialog"
                  :deleteURL="componentURL"
                  :event-name="eventName"
                >
                </delete-item-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

import DeleteItemDialog from "@/components/miscellaneous/dialogs/DeleteItemDialog.vue";

import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";

const prayerRepository = RepositoryFactory.get("prayer");

export default {
  components: {
    DeleteItemDialog,
  },
  data: () => {
    return {
      title: "Prayers",
      headers: [
        { text: "Date", value: "created_at", sortable: false },
        { text: "User", value: "name", sortable: false },
        { text: "Prayer", value: "request", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      prayers: [],
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      params: {
        page: 1,
        per_page: 15,
        with_user: 1,
        search: "",
      },
      totalItems: 0,
      loading: false,
      componentURL: "api/prayer-requests",
      setupDeleteDialog: {
        button: {
          rounded: true,
          small: true,
          depressed: true,
          dark: true,
          class: "mx-1",
          icon: false,
          label: "Delete",
          isIcon: false,
        },
        card: {
          title: "Users Delete Item",
        },
      },
      eventName: "reloadItems_prayers",
    };
  },
  methods: {
    getData() {
      this.getPrayers();
    },
    async paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;

      await this.getData();
    },
    getPrayers() {
      let params = { ...this.params };

      this.loading = true;

      prayerRepository
        .get({ params })
        .then((response) => {
          let {
            data: { responseData: data }
          } = response;

          let items = data.data.map(item => {
            return {
              id: item.id,
              created_at: item.created_at,
              name: `${item.user.first_name} ${item.user.last_name}`,
              request: item.request
            };
          });
          
          this.prayers = items;

          this.totalItems = data.total;
        })
        .catch(() => {
          this.prayers = [];
        })
        .finally(() => {
          this.loading = false;
        })
    },
    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    setupEventBus() {
      this.reloadItemsDeleteEventBus();
    }
  },
  mounted() {
    this.setupEventBus();
  },
};
</script>
