<template>
  <div>
    <v-btn v-bind="buttonAttrs" @click.stop="showDialog()">
      <template v-if="buttonAttrs.isIcon">
        <v-icon>{{ buttonAttrs.iconName }}</v-icon>
      </template>
      <template v-else>
        {{ buttonAttrs.label }}
      </template>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="550" scrollable>
      <v-card>
        <v-card-title class="title text-gray">
          {{ cardAttrs.title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions class="px-6 py-4">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            depressed
            small
            width="100"
            color="error"
            class="text-white"
            :loading="loading"
            @click="submit()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DELETE_ITEM_EVENT_BUS } from "./js/DeleteItemEventBus";

import Repository from "@/services/repository";

export default {
  data: () => ({
    dialog: false,
    componentKey: 0,
    item: {},
    loading: false,
    text: "Are you sure you want to delete this item?"
  }),
  props: {
    itemID: {
      type: Number,
      required: true
    },
    eventName: {
      type: String,
      /**
       * event that triggers the eventbus
       */
      default: null
    },
    deleteURL: {
      type: String,
      required: true
    }
  },
  computed: {
    buttonAttrs: function() {
      return this.$attrs.setup.button;
    },
    cardAttrs: function() {
      return this.$attrs.setup.card;
    }
  },
  methods: {
    async showDialog() {
      this.dialog = await true;
    },
    closeDialog() {
      this.componentKey++;
      this.dialog = false;
    },
    async submit() {
      this.loading = true;

      let url = `${this.deleteURL}/${this.itemID}`;

      await Repository.delete(url)
        .then(response => {
          console.log({ response });

          DELETE_ITEM_EVENT_BUS.$emit(this.eventName);

          // this.MIXINS_SHOW_SNACKBAR("success", response.data.message);
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    }
  }
};
</script>
